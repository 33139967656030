import React, { useEffect, useState, useCallback, useContext } from 'react'
import moment from 'moment'
import { graphql, Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer, Text } from '../../components/common/typography'
import GatsbyImage from 'gatsby-image'
import { BLACK_COLOR_RGB, PRIMARY_COLOR, BLACK_COLOR, WHITE_COLOR, WHITE_COLOR_RGB, BLUE_COLOR } from '../../components/common/color'
import bp from '../../components/common/breakpoints'
import { getBlogPosts } from '../../utils/requests'
import SEO from '../../components/seo'
import Select from '../../components/select'
import Button from '@material-ui/core/Button'
import { AuthContext, postTypeAction } from '../../context/auth-context'
import AppLayout from '../../components/layouts/appLayout'
import { motion } from 'framer-motion'

const Content = styled.div`
  position: relative;
  padding-top: 2rem;
  height: 100%;
`
const BackgroundIMG = styled.div`
  display: none;
  ${bp[2]}{
    display: unset;
    height:380px;
    position: absolute;
    top: 14vh;
    left: 0;
    width: 75%;
    height: 66vh;
  }
  ${bp[4]}{
    width: 90%;
  }
`

const BackgroundFixed = styled.div`
  ${bp[2]}{
    height:380px;
    position: fixed;
    width: 75%;
    height: 66vh;
  }
  ${bp[4]}{
    width: 90%;
  }
`

const PostsContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
  margin: 3rem auto 0;
  padding-bottom: 3rem;
  ${bp[1]} {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  ${bp[2]} {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  ${bp[3]} {
    max-width: 1080px;
  }
`
const PostCard = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  border-radius: 4px;
  background: white;
  height: 280px;
  box-sizing: border-box;
  // background: rgba(255, 255, 255, 0.7);
  // backdrop-filter: blur(40px);
  background: ${BLACK_COLOR};
  z-index: 0;
`
const PostTitle = styled.h4`
  font-weight: bold;
  font-family: 'Manrope';
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
  color: ${WHITE_COLOR}
`
const PostSummary = styled(Text)`
  color: ${WHITE_COLOR_RGB(0.4)};
  font-weight: 400;
  overflow: hidden;
  margin-bottom: 1rem;
  font-family: 'Manrope';
  font-size: 1rem;
`
const PostDetailsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
`
const PostDate = styled.span`
  color: ${WHITE_COLOR};
  font-family: 'Source Code Pro';
  font-size: 12px;
`
const PostAuthor = styled.div`
  color: ${WHITE_COLOR_RGB(0.5)};
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 800;
`

const PostMoreInfoLink = styled(Link)`
  color: ${PRIMARY_COLOR};
  justify-self: end;
  font-weight: 600;
  font-size: 0.75rem;
  :hover {
    color: ${WHITE_COLOR};
  }
`
const SelectContainer = styled.div`
  display: flex;
  padding-top: 3rem;
  padding-bottom: 1rem;
  gap: 2rem;
  justify-content: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const BlogPage = ({ data }) => {
  const { background, allcareersOpportunity } = data
  const [blogPosts, setBlogPosts] = useState([])
  const [filter, setFilter] = useState('')
  const [authorFilter, setAuthorFilter] = useState(null)
  const { dispatch } = useContext(AuthContext)

  const createBlog = () => {
    navigate('/admin')
    dispatch(postTypeAction('career'))
  }

  const normalizeMarkdownPosts = (mdPosts) => {
    return mdPosts.map(({ node: post }) => ({
      ...post.frontmatter,
      id: post.id,
      slug: `/careers-opportunity/${post.parent.name}`,
    }))
  }

  const normalizeAwsPosts = (awsPosts) => {
    return awsPosts.map((post) => ({
      ...post,
      mainDate: post.date,
      date: moment(post.date).format('MMMM Do, YYYY'),
      slug: `/careers-opportunity/${post.id}`,
    }))
  }

  const normalizePosts = useCallback((awsPosts, mdPosts) => {
    const allPosts = [
      ...normalizeAwsPosts(awsPosts),
      ...normalizeMarkdownPosts(mdPosts),
    ]

    allPosts.sort((a, b) => {
      if (a?.mainDate && b?.mainDate) {
        return new Date(b.mainDate).getTime() - new Date(a.mainDate).getTime()
      }

      return 0
    })

    setBlogPosts(allPosts)
  }, [])

  useEffect(() => {
    const fetchAwsPosts = async () => {
      const { data: awsPosts } = await getBlogPosts('career')
      normalizePosts(awsPosts, allcareersOpportunity.edges)
    }

    fetchAwsPosts()
  }, [allcareersOpportunity.edges, normalizePosts])

  const postsFilter =
    filter && authorFilter
      ? blogPosts.filter((item) => {
        return item.author === filter
      })
      : blogPosts.filter((item) => {
        return item.topic === filter
      })

  return (
    <AppLayout>
      <SEO title="Careers" canonical="careers-opportunity" />
      <Content>
        <BackgroundIMG>
          <BackgroundFixed>
            <GatsbyImage fluid={background.fluid} style={{ borderRadius: '4px' }} />
          </BackgroundFixed>
        </BackgroundIMG>
        <>
          <HeadingContainer icon={'arrow-left'} style={{ justifyContent: 'center' }} title="Careers Opportunities" />
          <SelectContainer>
            {/* We hide filter to avoid breack the style */}
            <div style={{ display: 'none' }}>
              <Select
                blogs={blogPosts}
                title={'Author'}
                setFilter={setFilter}
                setAuthorFilter={setAuthorFilter}
                dis
              />
              <Select
                blogs={blogPosts}
                title={'Topic'}
                setFilter={setFilter}
                setAuthorFilter={setAuthorFilter}
              />
            </div>
          </SelectContainer>
          {/* <ButtonContainer>
            <Button
              variant="contained"
              style={{
                backgroundColor: PRIMARY_COLOR,
                color: 'white',
                fontWeight: 'bold',
              }}
              onClick={createBlog}
            >
              Create Open Opportunity
            </Button>
          </ButtonContainer> */}
          <PostsContainer transition={{
            delay: 1,
            type: 'spring',
            ease: 'easeOut',
            duration: 2,
          }}
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}>
            {!filter &&
              blogPosts.length > 0 &&
              blogPosts.map((post) => (
                <PostCard key={post.id}>
                  <PostAuthor></PostAuthor>
                  <PostTitle>{post.title}</PostTitle>
                  <PostSummary>{post.description}</PostSummary>
                  <PostDetailsContainer>
                    <PostDate>{post.date}</PostDate>
                    <PostMoreInfoLink to={post.slug}>Keep Reading</PostMoreInfoLink>
                  </PostDetailsContainer>
                </PostCard>
              ))}
            {filter &&
              postsFilter.length > 0 &&
              postsFilter.map((post) => (
                <PostCard key={post.id}>
                  <PostAuthor></PostAuthor>
                  <PostTitle>{post.title}</PostTitle>
                  <PostSummary>{post.description}</PostSummary>
                  <PostDetailsContainer>
                    <PostDate>{post.date}</PostDate>
                    <PostMoreInfoLink to={post.slug}>Keep Reading</PostMoreInfoLink>
                  </PostDetailsContainer>
                </PostCard>
              ))}
          </PostsContainer>
        </>
      </Content>
    </AppLayout>
  )
}

export const query = graphql`
  query {
    background: imageSharp(
      fluid: { originalName: { eq: "blog-background.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100, maxWidth: 1400, maxHeight: 320) {
        ...GatsbyImageSharpFluid
      }
    }
    allcareersOpportunity: allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "careers-opportunity" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            description
            author
          }
          parent {
            ... on File {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default BlogPage
